import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  useTheme,
  Button,
  Typography,
  Card,
  CardActions,
  CardContent,
  darken,
  IconButton,
  useMediaQuery,
  Tabs,
  Tab,TextField,InputAdornment
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Close, ArrowBack } from "@mui/icons-material";

import { setAlert } from "../features/slices/alertSlice";
import { selectCurrentToken } from "../features/slices/authSlice";
import { openModal, closeModal } from "../features/slices/modalSlice";

import Image from "../components/common/Image";
import Backdrop from "../components/common/Backdrop";
import DOMPurify from "dompurify";

import {
  useLazyGetBonusListQuery,
  useLazyGetBonusDetailsQuery,
  useClaimBonusMutation,
} from "../features/api/bonusApiSlice";
import {
  useLazyGetPublicBonusListQuery,
  useLazyGetPublicBonusDetailsQuery,
} from "../features/api/publicApiSlice";
import { lighten } from "@material-ui/core";
import * as Constant from "../features/constant";
import CopiedTooltip from "../components/common/CopiedTooltip";
import {useGetShareLinkQuery} from "../features/api/userApiSlice";

const Bonus = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [bonusCategory, setBonusCategory] = useState("ALL");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isClaimTrigger, setIsClaimTrigger] = useState(false);
  const [bonusType, setBonusType] = useState(null);
  const [isBonusSelected, setIsBonusSelected] = useState(false);

  const [
    getBonusList,
    {
      data: bonusList,
      isFetching: isBonusListFetching,
      isLoading: isBonusListLoading,
      isError: isBonusListError,
      isSuccess: isBonusListSuccess,
      error: bonusListError,
    },
  ] = useLazyGetBonusListQuery({ prefetch: true });

  // useEffect(() => {
  //   // Track the PageView event with Facebook Pixel
  //   window.fbq("trackCustom", "PageView");
  // }, []);

  const [
    getPublicBonusList,
    {
      data: publicBonusList,
      isFetching: isPublicBonusListFetching,
      isLoading: isPublicBonusListLoading,
      isError: isPublicBonusListsError,
      isSuccess: isPublicBonusListSuccess,
      error: publicBonusListError,
    },
  ] = useLazyGetPublicBonusListQuery({ prefetch: true });

  const [
    getPublicBonusDetails,
    {
      data: publicBonusDetails,
      isFetching: isPublicBonusDetailsFetching,
      isLoading: isPublicBonusDetailsLoading,
      isError: isPublicBonusDetailsError,
      isSuccess: isPublicBonusDetailsSuccess,
      error: publicBonusDetailsError,
    },
  ] = useLazyGetPublicBonusDetailsQuery({ prefetch: true });

  const [
    getBonusDetails,
    {
      data: bonusDetails,
      isFetching: isBonusDetailsFetching,
      isLoading: isBonusDetailsLoading,
      isError: isBonusDetailsError,
      isSuccess: isBonusDetailsSuccess,
      error: bonusDetailsError,
    },
  ] = useLazyGetBonusDetailsQuery({ prefetch: true });

  const [
    claim,
    {
      data: claimBonus,
      isFetching: isClaimBonusFetching,
      isLoading: isClaimBonusLoading,
      isError: isClaimBonusError,
      isSuccess: isClaimBonusSuccess,
      error: claimBonusError,
    },
  ] = useClaimBonusMutation({ prefetch: true });

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  const handleClaimBonus = async (bonusCode) => {
    let data = {
      bonusCode: bonusCode,
    };
    await claim(data).unwrap();
  };

  useEffect(() => {
    if (isClaimBonusError) {
      dispatch(
          openModal({
            title: isClaimBonusError?.data?.message,
          })
      );
    }
  }, [isClaimBonusError]);


  useEffect(() => {
    if (!isClaimBonusLoading && isClaimBonusSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("promotion.successmessage"),
          variant: "success",
        })
      );
      dispatch(closeModal());
      // window.fbq('trackCustom', 'CompletePromotion');
    }
  }, [isClaimBonusLoading, isClaimBonusSuccess, isClaimBonusError]);

  useEffect(() => {
    getPublicBonusList(bonusCategory);
    getBonusList(bonusCategory);
  }, [bonusCategory]);

  useEffect(() => {
    // Reset the border for all buttons to transparent
    const allButtons = document.querySelectorAll('[id^="button-"]');
    allButtons.forEach((button) => {
      button.style.border = '6px solid transparent';
    });

    // Apply the red border to the selected button
    const selectedButton = document.getElementById(`button-${selectedItem?.bonusCode}`);
    if (selectedButton) {
      selectedButton.style.border = `6px solid ${theme.palette.primary.main}`;
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      (!isBonusDetailsLoading && isBonusDetailsSuccess) ||
      (!isPublicBonusDetailsLoading && isPublicBonusDetailsSuccess)
    ) {

      if(isClaimTrigger){
        dispatch(
            openModal({
              title:
                  publicBonusDetails?.result?.displayTitle ||
                  bonusDetails?.result?.displayTitle,
              content: (
                  <>
                    {(bonusList || publicBonusList)?.result?.bonus?.map((item, index) => {
                      const isSelected = selectedItem === item;

                      if (item.bonusType === bonusType) {
                        return (
                            <Grid item xs={1} sm={1} md={1} key={index}>
                              <Button
                                  id={`button-${item?.bonusCode}`}
                                  variant="text"
                                  onClick={() => {
                                    const newSelectedItem = isSelected ? null : item;

                                    setSelectedItem(newSelectedItem);
                                    setIsBonusSelected(!!newSelectedItem); // Set to true if a bonus is selected
                                  }}
                                  sx={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    border: isSelected ? theme.palette.primary.main : '6px solid transparent',
                                    "&:hover": {
                                      border: isSelected ? theme.palette.primary.main : '6px solid transparent',
                                    },
                                    padding:'0'
                                  }}
                              >
                                <Card
                                    variant="outlined"
                                    display="flex"
                                    flexDirection="column"
                                    sx={{
                                      p: 1,
                                      bgcolor: theme.palette?.background?.paper,
                                      borderWidth: 0,
                                      padding:'0'
                                    }}
                                >
                                  <Image src={item.thumbnailUrl} alt={Constant.ALT_TAG_BONUS_IMG} />
                                </Card>
                              </Button>
                            </Grid>
                        );
                      } else {
                        return null; // Skip rendering if bonusCode doesn't match
                      }
                    })}
                  </>
              ),
              button: !token
                  ? [
                    {
                      label: t("promotion.login"),
                      onSubmit: () => {
                        dispatch(closeModal());
                        if (mobileView) {
                          navigate(Constant.getPath("/login"));
                        } else {
                          dispatch(
                              openModal({
                                title: t("dialog.proceedtologin"),
                                button: [
                                  {
                                    label: t("dialog.login"),
                                    onSubmit: () =>
                                        dispatch(closeModal()),
                                  },
                                ],
                              })
                          );
                        }
                      },
                    },
                    {
                      label: t("promotion.register"),
                      onSubmit: () => {
                        dispatch(closeModal());
                        // window.fbq('trackCustom', 'RegisterClick');
                        navigate(Constant.getPath("/register"));
                      },
                    },
                  ]
                  : token && bonusDetails?.result?.claimable
                      ? [
                        {
                          label: t("promotion.claim"),
                          onSubmit: () => {
                            dispatch(closeModal());
                            handleClaimBonus(
                                selectedItem?.bonusCode
                            );
                          },
                          disabled:
                          !selectedItem?.isActivated ,
                        },
                      ]
                      : [],
            })
        );
      }else{
        setIsClaimTrigger(false);
        dispatch(
        openModal({
          title:
            publicBonusDetails?.result?.displayTitle ||
            bonusDetails?.result?.displayTitle,
          content: (
            <>
              <Image
                src={
                  (publicBonusDetails?.result?.bonusTypeThumbnailUrl || publicBonusDetails?.result?.thumbnailUrl) ||
                    (bonusDetails?.result?.bonusTypeThumbnailUrl || bonusDetails?.result?.thumbnailUrl)
                }
                sx={{
                  width: "80%",
                }}
                alt={Constant.ALT_TAG_BONUS_IMG}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    publicBonusDetails?.result?.content ||
                      bonusDetails?.result?.content
                  ),
                }}
              ></div>
              {token && bonusDetails?.result?.bonusCode==='B044' && (
                  <div style={{ display: 'flex', justifyContent: 'center',
                    position: "absolute", bottom: 0, width: "100%", padding: "20px",
                    background: theme.palette.background.paper, left: 0 }}>
                    <TextField
                        label={t("walletcard.myaccountpage.referrallink")}
                        value={shareLink?.result?.url}
                        InputLabelProps={{ shrink: true }}
                        disabled
                        type="text"
                        size="small"
                        sx={{ width: "400px" }}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                    disableRipple
                                    sx={{
                                      "&.MuiButtonBase-root:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                >
                                  <CopiedTooltip text={shareLink?.result?.url} />
                                </IconButton>
                              </InputAdornment>
                          ),
                        }}
                    />
                  </div>
              )}

            </>
          ),
          button: !token
            ? [
                {
                  label: t("promotion.login"),
                  onSubmit: () => {
                    dispatch(closeModal());
                    if (mobileView) {
                      navigate(Constant.getPath("/login"));
                    } else {
                      dispatch(
                        openModal({
                          title: t("dialog.proceedtologin"),
                          button: [
                            {
                              label: t("dialog.login"),
                              onSubmit: () => dispatch(closeModal()),
                            },
                          ],
                        })
                      );
                    }
                  },
                },
                {
                  label: t("promotion.register"),
                  onSubmit: () => {
                    dispatch(closeModal());
                    // window.fbq('trackCustom', 'RegisterClick');
                    navigate(Constant.getPath("/register"));
                  },
                },
              ]
            : [],
        })
      );
    }
    }
  }, [bonusDetails, publicBonusDetails, token,selectedItem]);

  return (
    <Box
      sx={{
        background: `url('${publicBonusList?.webBackground[0]?.thumbnailUrl}')`,
      }}
    >
      {mobileView && (
        <Grid container justify="center" py={1}>
          <Grid item xs={4}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <Typography
              textAlign="center"
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              {t("promotion.title")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {/* <Container> */}
          {publicBonusList?.result?.bonusCategory?.map((category, index) => (
            <>
              <Tab
                key={index}
                label={category.label}
                sx={{
                  color: theme.palette.primary.main,
                  borderBottom:
                    bonusCategory === category.code &&
                    `2px solid ${theme.palette.primary.main}`,
                  fontWeight: 600,
                }}
                onClick={() => setBonusCategory(category.code)}
              />
            </>
          ))}
          {/* </Container> */}
        </Tabs>
      </Box>
      {(isClaimBonusLoading || isClaimBonusFetching) && <Backdrop />}
      {/* Promotion category tabs */}
      <Container
        sx={{
          px: mobileView && 0,
        }}
      >
        <Box
          pt={{ xs: 1, sm: 3, lg: 3, xl: 3 }}
          pb={{ xs: 8, sm: 3, lg: 3, xl: 3 }}
          sx={{
            minHeight: mobileView && "100vh",
          }}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            {(bonusList || publicBonusList)?.result?.bonus?.map(
              (list, index) => (
                  (list.bonusTypeDisplay || list.bonusType==='DEPOSIT' || list.bonusType==='CUSTOM'|| list.bonusType==='REFERRAL'|| list.bonusType==='RESCUE'||list.bonusType==='RESCUE_ESPORT') ?(
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <Card
                    variant="outlined"
                    display="flex"
                    flexDirection="column"
                    sx={{
                      p: 1,
                      bgcolor: theme.palette.background.paper,
                      borderWidth: 0,
                    }}
                  >
                    <Image src={list.bonusTypeDisplay?list.bonusTypeThumbnailUrl:list.thumbnailUrl} alt={Constant.ALT_TAG_BONUS_IMG}/>
                    <CardContent
                      display="flex"
                      flexDirection="column"
                      sx={{ p: 1 }}
                    >
                      <Grid container spacing={2}>
                        {list?.bonusCategory?.map((cat) => (
                          <Grid item>
                            <Box
                              sx={{
                                borderRadius: 1,
                                backgroundColor: darken(
                                  theme.palette.background.paper,
                                  0.2
                                ),
                                px: 1,
                              }}
                            >
                              <Typography color={"white"} fontSize={11}>
                                {cat}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Typography
                        mt={1}
                        fontWeight={600}
                        color={theme.palette.primary.main}
                      >
                        {list.displayTitle}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="white"
                        fontSize={14}
                      >
                        {list.description}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: list.claimable
                          ? "center"
                          : "space-between",
                      }}
                    >
                      {token && list.claimable && (
                        <Button
                          variant="outlined"
                          sx={{
                            p: 1,
                            fontWeight: "600",
                            color: theme.palette.primary.main,
                            borderRadius: "10px",
                            fontSize: "12px",
                            width: "100%",
                            "&.Mui-disabled": {
                              backgroundColor: lighten(
                                theme.palette.background.paper,
                                0.2
                              ),
                              color: lighten(
                                theme.palette.background.paper,
                                0.02
                              ),
                            },
                          }}
                          onClick={() => {
                            if(token && bonusList?.result && (list.bonusType==='CUSTOM'|| list.bonusType==='REFERRAL'|| list.bonusType==='DEPOSIT' || list.bonusType==='RESCUE'||list.bonusType==='RESCUE_ESPORT')){
                               handleClaimBonus(
                                   list?.bonusCode
                              );
                            }
                            else if (token && bonusList?.result) {
                                getBonusDetails(list.bonusCode);
                              setSelectedItem(null);
                              dispatch(
                                  openModal({
                                    title:
                                        publicBonusDetails?.result?.displayTitle ||
                                        bonusDetails?.result?.displayTitle,
                                    content: (
                                        <>
                                          {(bonusList || publicBonusList)?.result?.bonus?.map((item, index) => {
                                            const isSelected = selectedItem === item;

                                            if (item.bonusType === list.bonusType) {
                                              return (
                                                  <Grid item xs={1} sm={1} md={1} key={index}>
                                                    <Button
                                                        id={`button-${item?.bonusCode}`}
                                                        variant="text"
                                                        onClick={() => setSelectedItem(isSelected ? null : item)}
                                                        sx={{
                                                          textDecoration: 'none',
                                                          color: 'inherit',
                                                          border: isSelected ? theme.palette.primary.main : '6px solid transparent',
                                                          "&:hover": {
                                                            border: isSelected ? theme.palette.primary.main : '6px solid transparent',
                                                          },
                                                          padding:'0'
                                                        }}
                                                    >
                                                      <Card
                                                          variant="outlined"
                                                          display="flex"
                                                          flexDirection="column"
                                                          sx={{
                                                            p: 1,
                                                            bgcolor: theme.palette?.background?.paper,
                                                            borderWidth: 0,
                                                            padding:'0'
                                                          }}
                                                      >
                                                        <Image src={item.thumbnailUrl} alt={Constant.ALT_TAG_BONUS_IMG} />
                                                      </Card>
                                                    </Button>
                                                  </Grid>
                                              );
                                            } else {
                                              return null; // Skip rendering if bonusCode doesn't match
                                            }
                                          })}
                                        </>
                                    ),
                                    button: !token
                                        ? [
                                          {
                                            label: t("promotion.login"),
                                            onSubmit: () => {
                                              dispatch(closeModal());
                                              if (mobileView) {
                                                navigate(Constant.getPath("/login"));
                                              } else {
                                                dispatch(
                                                    openModal({
                                                      title: t("dialog.proceedtologin"),
                                                      button: [
                                                        {
                                                          label: t("dialog.login"),
                                                          onSubmit: () =>
                                                              dispatch(closeModal()),
                                                        },
                                                      ],
                                                    })
                                                );
                                              }
                                            },
                                          },
                                          {
                                            label: t("promotion.register"),
                                            onSubmit: () => {
                                              dispatch(closeModal());
                                              // window.fbq('trackCustom', 'RegisterClick');
                                              navigate(Constant.getPath("/register"));
                                            },
                                          },
                                        ]
                                        : token && bonusDetails?.result?.claimable
                                            ? [
                                              {
                                                label: t("promotion.claim"),
                                                onSubmit: () => {
                                                  dispatch(closeModal());
                                                  handleClaimBonus(
                                                      selectedItem?.bonusCode
                                                  );
                                                },
                                                disabled:
                                                    !selectedItem?.isActivated,
                                              },
                                            ]
                                            : [],
                                  })
                              );
                              setIsClaimTrigger(true);
                              setBonusType(list.bonusType);
                            } else {
                              // window.fbq('trackCustom', 'RegisterClick');
                              navigate(Constant.getPath("/register"));
                            }
                          }}
                          disabled={
                            token
                              ? !list.isActivated || isClaimBonusLoading
                              : false
                          }
                        >
                          {token
                            ? t("promotion.claim")
                            : t("promotion.register")}
                        </Button>
                      )}
                      {!token && (
                        <Button
                          variant="outlined"
                          sx={{
                            p: 1,
                            fontWeight: "600",
                            color: theme.palette.primary.main,
                            borderRadius: "10px",
                            fontSize: "12px",
                            width: "100%",
                          }}
                          onClick={() => {
                            // window.fbq('trackCustom', 'RegisterClick');
                            navigate(Constant.getPath("/register"));
                          }}
                        >
                          {t("promotion.register")}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        sx={{
                          p: 1,
                          ml: 1,
                          fontWeight: "600",
                          width: "100%",
                          borderRadius: "10px",
                          fontSize: "12px",
                          //background: `linear-gradient(180deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
                          backgroundImage: 'url(/assets/img/yellow_long.png)',
                          backgroundSize: "100% 100%",
                        }}
                        onClick={() => {
                          getBonusDetails(list.bonusCode);
                          getPublicBonusDetails(list.bonusCode);
                          setIsClaimTrigger(false);
                          if ((isPublicBonusListSuccess && !isPublicBonusDetailsLoading) ||
                              (isBonusDetailsSuccess && !isBonusDetailsLoading)) {
                            dispatch(
                              openModal({
                                title:
                                  publicBonusDetails?.result?.displayTitle ||
                                  bonusDetails?.result?.displayTitle,
                                content: (
                                  <>
                                    <Image
                                      src={
                                          (publicBonusDetails?.result?.bonusTypeThumbnailUrl || publicBonusDetails?.result?.thumbnailUrl) ||
                                          (bonusDetails?.result?.bonusTypeThumbnailUrl || bonusDetails?.result?.thumbnailUrl)
                                      }
                                      sx={{
                                        width: "80%",
                                      }}
                                      alt={Constant.ALT_TAG_BONUS_IMG}
                                    />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          publicBonusDetails?.result?.content ||
                                            bonusDetails?.result?.content
                                        ),
                                      }}
                                    ></div>
                                  </>
                                ),
                                button: !token
                                  ? [
                                      {
                                        label: t("promotion.login"),
                                        onSubmit: () => {
                                          dispatch(closeModal());
                                          if (mobileView) {
                                            navigate(Constant.getPath("/login"));
                                          } else {
                                            dispatch(
                                              openModal({
                                                title: t("dialog.proceedtologin"),
                                                button: [
                                                  {
                                                    label: t("dialog.login"),
                                                    onSubmit: () =>
                                                      dispatch(closeModal()),
                                                  },
                                                ],
                                              })
                                            );
                                          }
                                        },
                                      },
                                      {
                                        label: t("promotion.register"),
                                        onSubmit: () => {
                                          dispatch(closeModal());
                                          // window.fbq('trackCustom', 'RegisterClick');
                                          navigate(Constant.getPath("/register"));
                                        },
                                      },
                                    ]
    /*                              : token && bonusDetails?.result?.claimable
                                  ? [
                                      {
                                        label: t("promotion.claim"),
                                        onSubmit: () => {
                                          dispatch(closeModal());
                                          handleClaimBonus(
                                            bonusDetails?.result?.bonusCode
                                          );
                                        },
                                        disabled:
                                          bonusDetails?.result?.isActivated,
                                      },
                                    ]*/
                                  : [],
                              })
                            );
                            }
                        }}
                      >
                        {t("promotion.readmore")}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                  ):null
              )
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Bonus;
